
import { openWindowWithToken } from "@/utils/util.js";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import { isNumber, isEmpty } from "@/utils/validate";
import { useThingApply, useQueryActionHistory } from "@/network/thingApply";
import { useSelectItemList, things, attachSetting } from "@/network/lookUp.ts";
import roles from "@/types/roles.ts";
import { useCheckDataChange } from "@/network/common.ts";
import { ElMessageBox, ElMessage } from "element-plus";
import { useCityRelTown } from "@/network/chinaArea";
import {
  defineEmit,
  defineProps,
  onMounted,
  computed,
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef
} from "vue";

import Review from "@/views/shared/review.vue";
import LogHistory from "@/views/shared/logHistory.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {
    FileuploadListV1,
    SelectUnit,
    LogHistory,
    Review
  },
  emits: ["update:id"],
  props: ["id", "createCode"],
  setup(props, { emit }) {


    const refForm = ref(null);
    const router = useRouter();


    const user: any = inject("user");
    const title = ref("选择审批事项");

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });
    const selectThings = ref(null);
    const timeLimitTips = ref("");
    const tabActiveName = ref("submit");
    const collapseAcitveName = ref(["1", "2", "3"]);
    const projectType1Dic = [
      { label: "修缮工程", value: "1" },
      { label: "展示工程", value: "2" },
      { label: "迁建工程", value: "3" },
      { label: "抢险加固工程", value: "4" },
      { label: "其他保护性设施建设工程", value: "5" },
    ];
    const projectType2Dic = [
      { label: "安防工程", value: "1" },
      { label: "消防工程", value: "2" },
      { label: "防雷工程", value: "3" },
    ];
    const projectType2ComposeDic = [
      { label: "入侵报警系统", value: "1" },
      { label: "视频监控系统", value: "2" },
      { label: "出入口控制系统", value: "3" },
      { label: "电子巡更与通信系统", value: "4" },
      { label: "防爆安全监测系统", value: "5" },
      { label: "实体防护和人员防护系统", value: "6" },
      { label: "其它", value: "7" },
    ];



    const heritageClassDic = useSelectItemList("HeritageClass");

    // const thingsRowSpanSet = things.reduce((group, item) => {
    //   const key = item.code;
    //   group[key] = group[key] ?? [];
    //   group[key].push(item);
    //   return group;
    // },{});
    // console.log(thingsRowSpanSet,things);

    const formSetting: any = [
      {
        key: "56004",
        attach: [1, 2, 3, 4, 5, 6],
      },

      {
        key: "56005",
        attach: [
          {
            id: 1,
            required: false,
          },
          2,
          3,
          4,
          5,
          6,
        ],
      },

      // {
      //   key: "56006",
      //   attach: [
      //     1,
      //     7,
      //     3,
      //     {
      //       id: 4,
      //       required: false,
      //     },
      //     5,
      //     6,
      //   ],
      // },
      {
        key: "56010",
        attach: [
          {
            id: 1,
            required: false,
          },
          7,
          3,
          {
            id: 4,
            required: false,
          },
          5,
          6,
        ],
      },
      {
        key: "56012",
        attach: [
          12,
          {
            id: 1,
            required: false,
          },
          2,
          3,
          4,
          5,
          6,
        ],
      },
      {
        key: "56014-1",
        attach: [
          12,
          {
            id: 1,
            required: false,
          },
          9,
          8,
          {
            id: 4,
            required: false,
          },
          5,
          6,
        ],
        isProjectType1: true,
      },
      {
        key: "56014-2",
        attach: [
          {
            id: 1,
            required: false,
          },
          10,
          12,
          11,
          6,
        ],
        isProjectType2: true,
      },
      {
        key: "56022",
        attach: [
          1,
          {
            id: 11,
            required: true,
          },
          6,
        ],
      },
    ];

    const buildFormSetting = () => {
      formSetting.forEach((p) => {
        p.attach = p.attach.map((d) => {
          // console.log(d);
          let i = attachSetting.find(
            (e) => e.id == (typeof d == "number" ? d : d.id)
          );
          return typeof d == "number" ? i : Object.assign({}, i, d);
        });
      });

      //console.log(formSetting);
    };

    buildFormSetting();

    const rules = {
      applyFileTitle: [
        { required: true, message: "请输入申请文件标题！", trigger: "blur" },
      ],
      applyPerson: [
        { required: true, message: "请输入申请单位！", trigger: "blur" },
      ],
      doPerson: [{ required: true, message: "请输入经办人！", trigger: "blur" }],
      concactPhone: [
        { required: true, message: "请输入联系电话！", trigger: "blur" },
      ],
      projectName: [
        { required: true, message: "请输入项目名称！", trigger: "blur" },
      ],
      heritageUnitName: [
        { required: true, message: "请填写或选择不可移动文物！", trigger: "change" },
      ],
      // heritageUnitId: [
      //   { required: true, message: "请选择不可移动文物！", trigger: "change" },
      // ],
      heritageClass: [
        { required: true, message: "请选择不可移动文物类型！", trigger: "change" },
      ],
      heritageLevel: [
        { required: true, message: "请选择不可移动文物级别！", trigger: "change" },
      ],
      // provId: [
      //   {
      //     required: true, message: "请选择完整地区！", trigger: "change",
      //     validator: (rule, value, callback) => {
      //       if (!form.provId || !form.cityId || !form.countryId) {
      //         return callback(Error(rule.message));
      //       }
      //       callback();
      //     },
      //   },
      // ],
      isRevolution: [
        { required: true, message: "请选择是否革命文物！", trigger: "change" },
      ],
      isWorldHeritage: [
        { required: true, message: "请选择是否世界遗产！", trigger: "change" },
      ],
      planName: [{ required: true, message: "请输入方案名称！", trigger: "blur" }],
      planWriter: [
        { required: true, message: "请输入方案编制人！", trigger: "blur" },
      ],
      applyUnit: [
        {
          required: true,
          message: "请输入申报主体(文物保护单位管理机构或所有人)！",
          trigger: "blur",
        },
      ],
      projectType1ObjName: [
        { required: true, message: "请填写工程对象名称！", trigger: "blur" },
      ],
      projectType1: [
        { required: true, message: "请选择保护工程类型！", trigger: "change" },
      ],
      projectType2: [
        { required: true, message: "请选择安防工程类型！", trigger: "change" },
      ],
      projectType1FundSource: [
        { required: true, message: "请填写经费来源", trigger: "blur" },
      ],

      projectType2FundSource: [
        { required: true, message: "请填写经费来源", trigger: "blur" },
      ],
      projectType2Compose: [
        { required: true, message: "请选择工程主要构成！", trigger: "change" },
      ],
      projectType2Area: [
        {
          required: true,
          message: "请输入工程保护对象及范围！",
          trigger: "blur",
        },
      ],
      projectType2Amount: [
        { required: true, message: "请输入工程概算！", trigger: "blur" },
        {
          message: "填报不正确，请填写数字",
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (isEmpty(value) || !isNumber(value)) {
              return callback(Error(rule.message));
            }
            callback();
          },
        },
      ],
      doc_Type: [
        { required: true, message: "请选择批文类型！", trigger: "change" },
      ],
      doc_Year: [
        { required: true, message: "请选择批文年份！", trigger: "change" },
      ],
      doc_Serial_No: [
        { required: true, message: "请输入批文号！", trigger: "blur" },
        {
          message: "填报不正确，请填写数字",
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (isEmpty(value) || !isNumber(value)) {
              return callback(Error(rule.message));
            }
            callback();
          },
        },
      ],
    };

    const currentFormSetting = ref({
      key: null,
      isProjectType1: false,
      isProjectType2: false,
      attach: [],
    });




    const isFileUploading = ref(false);
    //const { visible, id } = toRefs(props.setting as any);
    const propsId = computed<number>({
      get: () => {
        return props.id;
      },
      set(v) {
        emit("update:id");
      },
    });

    const [isLoading, form, find, save, , review] = useThingApply(propsId);
    const { checkChange, setChangedEqual, oldValue } = useCheckDataChange(form);
    const [logs, queryHistory] = useQueryActionHistory(propsId);
    const now = new Date();

    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "cityId"),
      toRef(form, "countryId")
    );
    const docYearDic = ref([]);
    for (let i = 0; i < 10; i++) {
      docYearDic.value.push((now.getFullYear() - i).toString());
    }
    const docTypeDic = useSelectItemList("OfficialDocType");

    const setThingItem = (applyCode, applyType) => {
      const item = things.find(
        (d) =>
          d.code == applyCode && (applyType == null || d.applyType == applyType)
      );

      tabActiveName.value = "submit";
      selectThings.value = item;
      //console.log(item, applyCode, applyType);
      currentFormSetting.value = formSetting.find(
        (p) =>
          p.key ==
          selectThings.value.code +
          (selectThings.value.applyType ? `-${selectThings.value.applyType}` : "")
      );
      timeLimitTips.value = "本事项承诺办结时限为" + (item && item.timelimit);
      title.value =
        `[${item.code}]${item.thingsName}申请表 ` +
        (selectThings.value.applyType
          ? `- ${selectThings.value.applyTypeName}`
          : "");
      form.applyCode = item.code;
      form.applyType = item.applyType?.toString();
    };

    const queryDocTypeSearch = (queryString: string, cb: any) => {
      const labels = docTypeDic.value.map((p) => ({ value: p.label }));
      //console.log(labels);
      const results = queryString
        ? labels.filter(createFilter(queryString))
        : labels;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilter = (queryString: string) => {
      return (item) => {
        return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    };

    onMounted(() => {
      
      find().then((p) => {
        if (propsId.value > 0) {

          setTimeout(() => refForm.value.clearValidate(), 1);
          //selectUnitSetting.heritage_Name = p.heritageUnitName;
          setThingItem(p.applyCode, p.applyType);
          queryHistory();
        } else {
          const codeArr = props.createCode.split("-");
          setThingItem(codeArr[0], codeArr.length > 1 ? codeArr[1] : null);
        }
        setTimeout(() => {
          setChangedEqual();
        }, 1);

        // setTimeout(() => {
        //   console.log(oldValue, form, checkChange());
        // }, 5000)
      });
    });

    const attachRules = (i) => [
      {
        required: i.required,
        message: `请上传附件！`,
        validator: (rule, value, callback) => {
          if (i.required) {
            if (
              form.attachList.filter((p) => p.type == i.id.toString()).length == 0
            ) {
              return callback(Error(rule.message));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];

    const selectUnitChange = (item) => {
      form.heritageClass = item.heritageClass_ID;
      form.heritageLevel = item.heritageLevel_Code;
      form.heritageUnitName = item.heritage_Name;

      selectUnitSetting.heritage_Name = "";
    };

    const saveData = async () => {
      await save();
      setChangedEqual();
      if (propsId.value < 1) {
        propsId.value = form.id;
        router.push({
          name: "ReportAndReviewThingsManage",
          params: { id: form.id },
        });
      }

      //console.log("test");
    };
    const doSave = async (submit = false) => {
      if (submit) {
        //console.log(checkChange());
        if (checkChange()) {
          try {
            await ElMessageBox.confirm("检查到数据有修改，是否保存后再提交！？");
          } catch {
            ElMessage.info({
              message: "取消提交，请检查表单数据或保存后可再次提交",
              duration: 6000,
              type: "info",
            });
            return;
          }
          await saveData();
        }
        refForm.value.validate((valid) => {
          if (valid) {
            //执行提交
            ElMessageBox.confirm("确认提交审核吗？")
              .then(() => {
                review(form.id, { result: true }).then(() => {
                  router.push(`/ReportAndReview/Things`);
                });
              })
              .catch(() => {
                ElMessage.info({
                  message: "取消提交",
                  duration: 6000,
                  type: "info",
                });
              });
          } else {
            ElMessage.error("表单验证失败！请检查各表单项中的红色提示错误部分！");
            return;
          }
        });
      } else {
        await saveData();
      }
    };

    const onReviewSubmit = (result) => {
      //console.log(result);

      saveData().then(() => review(form.id, result).then((p) => {
        router.push(`/ReportAndReview/Things`);
      }));
    };


    return {
      roles,
      logs,
      isLoading,
      form,
      propsId,
      refForm,
      user,
      title,
      selectUnitSetting,
      selectThings,
      timeLimitTips,
      tabActiveName,
      collapseAcitveName,
      projectType1Dic,
      projectType2Dic,
      projectType2ComposeDic,
      heritageClassDic,
      formSetting,
      rules,
      currentFormSetting,
      isFileUploading,
      docTypeDic,
      dicCity,
      dicTown,
      docYearDic,
      doSave,
      onReviewSubmit,
      selectUnitChange,
      attachRules,
      queryDocTypeSearch,
    }
  }

})
